* {
  margin: 0px;
  box-sizing: border-box;
}
html {
  overflow-x: hidden !important;
}
:root {
  --primary-color: rgb(1, 41, 112);
  --secondary-color: rgb(65, 84, 241);
}
body {
  font-family: "Roboto", sans-serif;
  color: #444;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(65, 84, 241, 0.8);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4154f1;
}

.active {
  color: var(--secondary-color) !important;
}

.header {
  margin: 0px;
  width: 100%;
  height: 50px;
  /* background-color: #144394; */
  background-color: var(--primary-color);
  color: white;
  display: flex;
  justify-content: end;
  align-items: center;
}
.header-phone,
.header-socialMedia,
.header-email {
  padding-right: 20px;
}

.header-email > a {
  color: #fff;
  text-decoration: none;
}

.header-socialMedia ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  font-size: 20px;
}

.header-socialMedia ul li {
  padding: 0px 12px;
  cursor: pointer;
  color: white !important;
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
  .media-body .media-item .item-left {
    max-width: 15%;
  }
}

@media screen and (max-width: 750px) {
  .header {
    flex-direction: column;
    height: auto;
  }
  .header-phone,
  .header-socialMedia,
  .header-email {
    padding-right: 0px !important;
  }
}

.header-phone {
  letter-spacing: 1px;
}
.header-phone > .toll-free {
  font-weight: 500;
}

.nav-item {
  position: relative;
  align-items: center;
  font-family: Nunito, "Roboto" !important;
  padding: 12px;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
  white-space: nowrap;
  transition: 0.3s;
  cursor: pointer;
  letter-spacing: 0.3px;
}

.nav-menu > ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.login {
  background-color: var(--secondary-color);
  color: #fff !important;
  border: 1px solid var(--secondary-color) !important;
  padding: 7px 25px !important;
  border-radius: 50px;
  margin: 12px auto;
  transition: 0.3s;
}
.login:hover {
  background: #fff;
  color: var(--secondary-color) !important;
  transition: 0.3s;
}
.apply-now {
  background-color: #fff;
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  padding: 1px 20px;
  border-radius: 50px;
  margin: 15px 0px;
  margin-left: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
}
.apply-now:hover {
  background: #4154f1;
  color: #fff !important;
  transition: 0.3s;
}

.logo-img {
  cursor: pointer;
  margin-right: 10px;
}

.logo {
  height: 40px;
}

@media screen and (max-width: 1100px) {
  .logo {
    height: auto !important;
  }
}

.mobile-nav-toggler {
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

/* --------------Mobile navbar-------------------------- */

.navbar-mobile {
  width: 100%;
  height: 100%;
  z-index: 9997;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 22, 61, 0.9);
  transition: 0.3s;
  /* z-index: 999; */
}

.navbar-mobile ul {
  display: block !important;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile ul li {
  margin: 0px 25px;
  text-align: center;
}

.navbar-mobile .login {
  margin: 15px;
}

.navbar-mobile .mobile-nav-toggler {
  position: absolute;
  top: 25px;
  right: 15px;
  color: #fff;
}

.logo-brand {
  font-family: "Times New Roman", Times, serif !important;
}
/* --------------------------------------------- */

@media screen and (max-width: 1280px) {
  .mobile-nav-toggler {
    display: block;
  }

  #navbar ul {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .value-header p,
  .WhyCSIS-wrapper-header p,
  .description .description-header p {
    font-size: 30px !important;
  }
  .Academics_content {
    margin-top: -40px !important;
  }
  .ReachUS-content form {
    padding-top: 25px !important;
  }
  .Academics-img {
    height: auto !important;
  }
}
@media (max-width: 600px) {
  .value-header p,
  .WhyCSIS-wrapper-header p,
  .description .description-header p {
    font-size: 30px !important;
    font-weight: 600 !important;
    margin: 10px !important;
    margin-bottom: 30px !important;
    padding-bottom: 20px !important;
  }
  .about-title p {
    font-size: 30px !important;
    font-weight: 600 !important;
    margin-bottom: 30px !important;
  }
  .Admission-title {
    flex-direction: column;
  }
  .apply-now {
    max-width: 150px !important;
    margin: 10px auto !important;
    padding: 7px 20px !important;
  }
  .value-header p::after,
  .WhyCSIS-wrapper-header p::after,
  .description .description-header p::after,
  .about-title p::after {
    width: 70px !important;
    left: calc(50% - 40px) !important;
  }
  .description {
    padding: 20px 0px 20px 0 !important;
  }
}
@media (max-width: 1165px) {
  .nav-header .logo-brand .Business-name {
    font-size: 18px;
    letter-spacing: normal;
  }
  .nav-header .logo-brand .Business-tagline {
    font-size: 12px;
  }
}

@media (max-width: 410px) {
  .mobile-nav-toggler {
    display: block;
    font-size: 1.2rem;
    padding-right: 5px;
  }
  .nav-header .logo-brand .Business-name {
    font-size: 16px;
    letter-spacing: normal;
    padding-bottom: 5px;
  }
  .logo-img {
    margin-right: 5px !important;
  }
  .nav-header .logo-brand .Business-tagline {
    font-size: 10px;
  }

  .value-header p,
  .WhyCSIS-wrapper-header p,
  .description .description-header p {
    font-size: 20px !important;
    font-weight: 700 !important;
    margin: 20px !important;
    margin-top: 0px !important;
    padding-bottom: 10px !important;
  }
  .about-title p {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin: 0px 20px 20px 0px !important;
  }
  .value-header p::after,
  .WhyCSIS-wrapper-header p::after,
  .description .description-header p::after,
  .about-title p::after {
    width: 60px !important;
  }
  .AboutCSIS,
  .Academics,
  .Admission,
  .description,
  .Gallery,
  .ReachUS,
  .mandatory-disclosure,
  .Page-404-container,
  .description .description-content .content {
    padding: 20px 10px !important;
  }
  .about-content .column-first {
    padding-bottom: 20px !important;
  }
  .description-row {
    padding: 20px 0px !important;
  }

  .Gallery .Gallery-title h2, .mandatory-disclosure .mandatory-disclosure-title h2 {
    font-size: 25px !important;
  }
  .Gallery .Gallery-title h2::before,
  .Gallery .Gallery-title h2::after,
  .mandatory-disclosure .mandatory-disclosure-title h2::before,
  .mandatory-disclosure .mandatory-disclosure-title-title h2::after {
    width: 40px !important;
  }
  .ReachUS-content .info .email p,
  .ReachUS-content .info .address p,
  .ReachUS-content .info .phone p {
    font-size: 16px !important;
  }
}

@media (max-width: 290px) {
  .nav-header .logo-img {
    display: none !important;
  }
  .ReachUS-content .info .email p,
  .ReachUS-content .info .address p,
  .ReachUS-content .info .phone p {
    font-size: 14px !important;
  }
}
@media (max-width: 350px) {
  .nav-header .logo-brand .Business-name {
    font-size: 12px;
  }
}
@media (max-width: 320px) {
  .Gallery .Gallery-title h2::before, .mandatory-disclosure .mandatory-disclosure-title h2::before {
    margin: 0 5px 10px 0 !important;
  }
  .Gallery .Gallery-title h2::after, .mandatory-disclosure .mandatory-disclosure-title h2::after  {
    margin: 0 0 10px 5px !important;
  }
}

@media (max-width: 250px) {
  .nav-header {
    height: auto !important;
  }
  .container-fluid {
    flex-direction: column !important;
  }
  .AboutCSIS,
  .Academics,
  .Admission,
  .ReachUS,
  .mandatory-disclosure {
    padding: 10px !important;
  }
  .value-header p,
  .WhyCSIS-wrapper-header p,
  .description .description-header p,
  .about-title p {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .value-header p::after,
  .WhyCSIS-wrapper-header p::after,
  .description .description-header p::after,
  .about-title p::after {
    width: 50px !important;
  }
}

.Business-name,
.Business-tagline {
  font-weight: 900;
  letter-spacing: 1px;
  color: #012970;
}

.Business-name {
  cursor: pointer;
  font-size: 20px;
  margin-top: -3px;
}

.Business-tagline {
  font-size: 12px;
}

.nav-header {
  height: 65px !important;
  display: flex;
  align-items: center;
}

.navBar-scroll {
  background: #fff;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 6%),
    0 0 2px rgb(0 0 0 / 7%);
}

.carousel .carousel-item {
  height: 550px;
}

@media screen and (min-width: 1500px) {
  .carousel .carousel-item {
    height: auto !important;
  }
}

a:-webkit-any-link {
  text-decoration: none;
}

@media screen and (max-width: 400px) {
  .Page-404-rightContainer {
    padding: 20px !important;
  }
  .media-body .media-item .item-right {
    padding-left: 10px;
  }
}

.Page-404-leftContainer {
  height: 400px;
}

@media screen and (max-width: 600px) {
  .Page-404-leftContainer {
    height: auto !important;
  }
}

.Page-404-rightContainer h1 {
  font-size: 48px;
  font-weight: 700;
  color: var(--primary-color);
}
.Page-404-rightContainer h2 {
  color: #444444;
  font-size: 26px;
  font-weight: bold;
}

.Page-404-rightContainer span {
  color: var(--primary-color);
  font-size: 16px;
}
.Page-404-rightContainer .go-home {
  margin-top: 20px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: var(--secondary-color);
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.Page-404-rightContainer .go-home span {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  color: #fff !important;
  padding-right: 5px;
}

.Page-404-rightContainer .go-home i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.values .box {
  padding: 30px;
  box-shadow: 0px 0 5px rgb(1 41 112 / 8%);
  transition: 0.3s;
  height: 100%;
  border-radius: 8px;
}

.values .box p {
  text-align: justify !important;
}

.values .box:hover {
  box-shadow: 0px 0 30px rgb(1 41 112 / 8%);
}

.box-body {
  padding: 20px !important;
}

.values .box img {
  padding: 0px 30px 20px 30px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .box:hover img {
  transform: scale(1);
}

.values .box h3 {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 18px;
  text-align: center !important;
}

.box-img {
  max-height: 200px;
}
.WhyCSIS-wrapper-header {
  padding: 30px;
}
.value-header p,
.WhyCSIS-wrapper-header p,
.description .description-header p {
  text-align: center;
  margin: 30px;
  font-size: 38px;
  line-height: 42px;
  font-weight: 600;
  position: relative;
  padding-bottom: 25px;
}
.value-header p::after,
.WhyCSIS-wrapper-header p::after,
.description .description-header p::after {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 3px;
  background: #012970;
  bottom: 0;
  left: calc(50% - 70px);
}

.WhyCSIS-wrapper {
  overflow: hidden;
}

.WhyCSIS-wrapper .WhyCSIS-middle-block img {
  margin: 0 auto;
  display: table;
  height: 500px;
}
.WhyCSIS-wrapper .WhyCSIS-left-block ul,
.WhyCSIS-wrapper .WhyCSIS-right-block ul {
  padding-left: 0px !important;
}

.WhyCSIS-wrapper .WhyCSIS-left-block ul,
.WhyCSIS-wrapper .WhyCSIS-right-block ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
}

.WhyCSIS-left-block ul li,
.WhyCSIS-right-block li {
  height: 100px;
  margin: 5px 0px;
  /* display: block; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WhyCSIS-content-wrap {
  position: relative;
  width: 70%;
  float: left;
}

.WhyCSIS-content-left-wrap {
  text-align: right;
  padding-right: 15px;
}

.WhyCSIS-content-right-wrap {
  text-align: left;
  padding-left: 15px;
}

.WhyCSIS-content-wrap span {
  font-weight: 700;
  color: black !important;
  font-size: 18px;
  margin: 0px;
  transition: 0.3s;
}

.WhyCSIS-image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30%;
  height: 100px;
  transition: transform 0.7s ease-in-out !important;
}

.WhyCSIS-image-wrap img {
  margin: 0 auto;
  display: table;
}

.WhyCSIS-image-wrap:hover {
  transform: rotate(360deg) !important;
}

.WhyCSIS-image-wrap-bg {
  height: 95px;
  width: 95px;
  background-color: #ffc000;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 990px) {
  .WhyCSIS-middle-block {
    display: none;
  }
}

@media only screen and (max-width: 780px) {
  .WhyCSIS-left-block ul li {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }

  .WhyCSIS-right-block ul li {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .WhyCSIS-content-wrap {
    text-align: center;
  }
}

.description {
  padding: 40px 0;
  overflow: hidden;
}

.description .description-content {
  background-color: #f6f9ff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2;
}
.description .description-content p {
  text-align: justify !important;
}

.description .description-content .content {
  padding: 40px;
}

.academics-content .content {
  padding: 0px 20px;
}
.description-content .content span {
  margin-bottom: 10px;
}
.description-content .content span i {
  font-size: 16px;
  margin-right: 10px;
}
.description-content .content span p {
  margin: 0px !important;
}
.description h2,
.Admission-content h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.mandatory-disclosure-subtitle{
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 10px;
  text-decoration: underline;
}

.description-row {
  padding: 30px 0px;
}
.footer .footer-top {
  background: white url(../public/assets/images/footer-bg.png) no-repeat left
    top;
  background-size: contain;
  border-top: 1px solid #e1ecff;
  border-bottom: 1px solid #e1ecff;
  padding: 60px 0 30px 0;
}

.footer .footer-facebook {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .social-links ul li {
  font-size: 22px;
  color: rgba(1, 41, 112, 0.6);
  line-height: 0;
  margin-right: 15px;
  transition: 0.3s;
  cursor: pointer;
}

.footer .footer-top .social-links ul li:hover {
  color: var(--primary-color) !important;
}

.footer .footer-top .footer-links ul,
.social-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  transition: 0.3s;
  line-height: 1;
  cursor: pointer;
}

.footer-right-box {
  width: 310px;
  height: 348px;
  background: #012970;
  padding: 5px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 32%);
  margin: auto;
}

.footer-address i {
  margin-right: 10px;
  font-size: 20px;
  color: var(--primary-color);
}

.footer-address .whatsapp_container {
  color: #444444 !important;
}

.footer .footer-info {
  margin-bottom: 30px;
}

.Academics-img {
  height: 410px;
}

@media screen and (max-width: 992px) {
  .footer-right-box {
    float: left;
  }
}

@media screen and (max-width: 600px) {
  .Academics .Academics_title h2,
  .Admission .Admission-title h2,
  .ReachUS .ReachUS-title h2,
  .Gallery .Gallery-title h2,
  .mandatory-disclosure .mandatory-disclosure-title h2 {
    font-size: 25px !important;
  }
  .footer .footer-col-1,
  .footer .footer-col-2,
  .footer-col-3 {
    margin-left: 40px;
  }
  .footer-col-3 {
    width: 90%;
  }
  .footer .footer-col-4 {
    margin-left: 40px;
  }
  .description .description-content h2 {
    text-align: center !important;
  }
}

@media screen and (max-width: 400px) {
  .footer .footer-col-1,
  .footer .footer-col-2,
  .footer .footer-col-3,
  .footer .footer-col-4 {
    margin-left: 20px;
  }
}

@media screen and (max-width: 300px) {
  .footer .footer-col-1,
  .footer .footer-col-2,
  .footer .footer-col-3,
  .footer .footer-col-4 {
    margin-left: 3px;
  }
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: var(--primary-color);
}

.footer .credits a {
  color: var(--secondary-color);
}

.footer .copyright {
  text-align: center;
  color: var(--primary-color);
}

.footer .footer-bottom {
  padding: 20px;
  background: #f6f9ff;
}

@media screen and (max-width: 800px) {
  .carousel .carousel-item {
    height: auto !important;
  }
}

@media screen and (max-width: 1100px) {
  .nav-header .logo .Business-tagline {
    margin-top: 0px !important;
  }
}

.AboutCSIS,
.Academics,
.Admission,
.ReachUS,
.mandatory-disclosure,
.Page-404-container {
  padding: 30px;
}

.AboutCSIS .about-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
}

.AboutCSIS .about-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #012970;
  margin: 4px 10px;
}

.AboutCSIS .about-title p {
  margin: 0;
  font-size: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary-color);
}

@media screen and (max-width: 400px) {
  .AboutCSIS .about-title p {
    font-size: 25px;
  }
  .about-title,
  .about-content .column-first {
    padding-right: 10px;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 480px) {
  .carousel-img {
    height: 270px !important;
  }
}

@media screen and (max-width: 1000px) {
  .AboutCSIS .about-content .row {
    flex-direction: column-reverse !important;
  }
  .about-content .column-first {
    padding-top: 20px;
  }
}

.about-title,
.about-content .column-first {
  padding-right: 20px;
  padding-bottom: 20px;
}

.about-content .column-first span,
.about-vision span {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
  margin-top: 20px;
  margin-bottom: 20px !important;
}

.about-content .column-first ul {
  padding: 0px !important;
  margin-left: 14px !important;
}
.about-content .column-first ul li {
  list-style: circle;
  line-height: 1.8em;
}
.about-content .marquee {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
  white-space: nowrap;
  transition: 0.3s;
  margin-top: 10px;
}

.Gallery {
  padding: 30px;
}

.Gallery .Gallery-item {
  margin-bottom: 20px !important;
}

.Gallery .Gallery-title h2, .mandatory-disclosure .mandatory-disclosure-title h2{
  font-size: 32px;
  font-weight: 600;
  /* text-transform: uppercase; */
  position: relative;
  color: #222222;
}

.Gallery .Gallery-title h2::before, .mandatory-disclosure .mandatory-disclosure-title h2::before{
  margin: 0 15px 10px 0;
}

.Gallery .Gallery-title h2::after, .mandatory-disclosure .mandatory-disclosure-title h2::after {
  margin: 0 0 10px 15px;
}

.Gallery .Gallery-title h2::before,
.Gallery .Gallery-title h2::after,
.mandatory-disclosure .mandatory-disclosure-title h2::before,
.mandatory-disclosure .mandatory-disclosure-title h2::after {
  content: "";
  width: 50px;
  height: 2px;
  background: #012970;
  display: inline-block;
}

.Gallery .Gallery-wrap {
  position: relative;
  overflow: hidden;
  transition: 1s;
  height: 238px;
}

.Gallery .Gallery-wrap .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgb(255, 255, 255, 0.75);
  border: 3px solid rgba(1, 41, 112, 0.2);
}
.Gallery .Gallery-wrap:hover .overlay {
  opacity: 1;
}

.Gallery .Gallery-wrap img {
  transition: ease-in 0.5s;
}

.Gallery .Gallery-wrap:hover img {
  transform: scale(1.12);
}

.Gallery .Gallery-wrap .overlay a {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .Gallery .Gallery-wrap .overlay a,
  .Gallery .Gallery-wrap:hover .overlay {
    display: none;
  }
}

.Gallery .Gallery-wrap .overlay a i {
  font-size: 24px;
  line-height: 0;
}

.Academics .Academics_title,
.Admission .Admission-title,
.ReachUS .ReachUS-title {
  text-align: center;
  position: relative;
}
.Academics .Academics_title h2,
.Admission .Admission-title h2,
.ReachUS .ReachUS-title h2 {
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 15px;
  position: relative;
  color: #222222;
}
.Academics .Academics_title h2::after,
.Admission .Admission-title h2::after,
.ReachUS .ReachUS-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 70px;
  height: 3px;
  background: var(--primary-color);
  bottom: 0;
  left: calc(50% - 35px);
}

.Academics .Academics_content .description-content {
  background-color: #fff !important;
}

.Admission-content h2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 10px !important;
}
.Admission-item {
  padding-bottom: 20px;
  padding: 0 0 1px 20px;
  margin-top: -2px;
  border-left: 2px solid #4154f1;
  position: relative;
}
.Admission-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #4154f1;
}

.Admission-item ul li {
  text-align: justify;
}

.Admission-item-2 span {
  font-weight: 700;
  font-size: 18px;
  color: #222222;
  line-height: 2;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 8px;
  bottom: 15px;
  text-decoration: none;
  z-index: 995;
  cursor: pointer;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--secondary-color);
  transition: all 0.4s;
  border: 2px solid var(--secondary-color);
}

.back-to-top i:hover {
  background: var(--secondary-color);
  color: #fff;
}

.ReachUS-content .info {
  width: 100%;
  background: #fff;
}
.ReachUS-content .info i {
  font-size: 20px;
  color: var(--secondary-color);
  float: left;
  width: 44px;
  height: 44px;
  background: #ecf9f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.ReachUS-content .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #37423b;
}
.ReachUS-content .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 18px;
  color: #657a6d;
  text-align: justify;
}

.ReachUS-content .info .email,
.ReachUS-content .info .phone {
  margin-top: 40px;
}

.ReachUS-content .info .address:hover i,
.ReachUS-content .info .email:hover i,
.ReachUS-content .info .phone:hover i {
  background: var(--secondary-color);
  color: #ecf9f0;
}

.ReachUS-content .contact-form .form-group {
  padding-bottom: 8px;
}

.ReachUS-content .contact-form button[type="submit"] {
  background: var(--secondary-color);
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--secondary-color);
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error_field_span {
  font-size: 12px;
  color: red;
  margin-left: 2px;
}

/*------ News and Media ---------*/

.footer .news-media {
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0 5px rgb(1 41 112 / 8%);
  flex-direction: column;
  padding: 0px !important;
}

.footer .news-media .media-header {
  background-color: var(--primary-color);
}

.footer .news-media .media-header div {
  color: #fff;
  font-weight: 500;
  letter-spacing: 1.6;
  padding: 8px 10px;
}

.footer .news-media {
  height: max-content;
}

.media-body{
  max-height: 300px !important;
  overflow-y: scroll !important;
}

.footer .news-media .media-item {
  display: flex;
  padding: 10px 15px;
}

.media-body .media-item .item-left {
  max-width: 25%;
}

.media-body .media-item img {
  max-height: 100%;
  max-width: 100%;
}

.media-body .media-item .item-right {
  font-size: 14px;
  padding-left: 20px;
  color: var(--primary-color);
  display: flex;
  justify-content: left;
  flex-direction: column;
  text-transform: uppercase;
}

@media screen and (max-width: 12000px) {
  .media-body .media-item .item-right {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .media-body .media-item .item-left {
    max-width: 15%;
  }
}

/* ---------------Home Page Popup Modal------ */
body.modal-open {
  padding-right: 0px !important;
}

.modal.fade .modal-dialog {
  transition: transform 0.8s ease-out;
}

.modal-content {
  border: none !important;
}

.modal_close {
  position: absolute;
  right: 0;
  top: -55px;
  cursor: pointer;
  color: #ffffff;
  font-size: 40px;
}

.modal-header {
  padding: 0px;
  border: none !important;
}

.home_modal img {
  border-radius: 4px;
}

.modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  min-width: 56%;
}

.we-are-hiring {
  color: var(--primary-color);
}

@media screen and (max-width: 1850px) {
  .home_modal .modal-dialog {
    min-width: 60%;
  }
  .admission_modal .modal-dialog {
    min-width: 60%;
  }
}

@media screen and (max-width: 900px) {
  .home_modal .modal-dialog {
    min-width: 85%;
  }
  .admission_modal .modal-dialog {
    min-width: 85%;
  }
}

@media screen and (max-width: 400px) {
  .home_modal .modal-dialog {
    min-width: 90%;
  }
  .admission_modal .modal-dialog {
    min-width: 90%;
  }
}

.fee_structure_object {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admission_modal Object {
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-map {
  width: 100%;
  height: 320px;
  background: url(../public/assets/images/loader.gif) center center no-repeat;
}


/* ------------ Mandatory Disclosure -------------- */

